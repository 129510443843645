import basicFlow from "./asyncHandler";
import { put, select } from "redux-saga/effects";
import { actions } from "../actions/operacao.actions";
import {
  authenticatedRequest,
  destinies,
  unauthenticatedRequest,
} from "../utils/api";
import { routeWatcher } from "./rotas.saga";
import {
  actions as routeActions,
  types as routes,
} from "../actions/rotas.actions";
import { getRouteArguments } from "../selectors/routes.selectors";
import { toast } from "react-toastify";
import { getFileNameFromHeader, transformFromDate } from "../utils/basic";
import { saveAs } from "file-saver";
import { getPreenchimentoOperacao } from "../selectors/operacao.selectors";

const tratarResultPreenchimento = ({ response }) => {
  if (!response?.data) {
    return;
  }
  if (response.data.operacao.imovel) {
    const contatoIq = response.data.operacao.imovel.contatoIq
      ? {
        ...response.data.operacao.imovel.contatoIq,
        _banco: response.data.operacao.imovel.contatoIq.banco,
        banco: !!response.data.operacao.imovel.contatoIq.banco
          ? { id: response.data.operacao.imovel.contatoIq.banco }
          : null,
      }
      : null;

    response.data.operacao.imovel = {
      ...response.data.operacao.imovel,
      contatoIq,
      _subTipoImovel: response.data.operacao.imovel.subTipoImovel,
      subTipoImovel: !!response.data.operacao.imovel.subTipoImovel
        ? { id: response.data.operacao.imovel.subTipoImovel }
        : null,
    };
  }

  if (response.data?.operacao?.participantes) {
    response.data.operacao.participantes =
      response.data.operacao.participantes.map((p) => {
        return {
          ...tratarParticipante(p),
          representantes: p.representantes.map(tratarParticipante),
        };
      });
  }
};

const tratarParticipante = (p) => {
  let result = {
    ...p,
    _dataNascimento: p.dataNascimento,
    dataNascimento: transformFromDate(p.dataNascimento),
    _dataCasamento: p.dataCasamento,
    dataCasamento: transformFromDate(p.dataCasamento),
    _dataAlteracaoContratual: p.dataAlteracaoContratual,
    dataAlteracaoContratual: transformFromDate(p.dataAlteracaoContratual),
    _nacionalidade: p.nacionalidade,
    nacionalidade: !!p.nacionalidade ? { id: p.nacionalidade } : null,
    _escolaridade: p.escolaridade,
    escolaridade: !!p.escolaridade ? { id: p.escolaridade } : null,
    _perfilRenda: p.perfilRenda,
    perfilRenda: !!p.perfilRenda ? { id: p.perfilRenda } : null,
    _tipoEmpresa: p.tipoEmpresa,
    tipoEmpresa: !!p.tipoEmpresa ? { id: p.tipoEmpresa } : null,
    _bancoConta: p.bancoConta,
    bancoConta: !!p.bancoConta ? { id: p.bancoConta } : null,
    _estadoCivil: p.estadoCivil,
    estadoCivil: !!p.estadoCivil ? { id: p.estadoCivil } : null,
    _qualificacao: p.qualificacao,
    qualificacao: !!p.qualificacao ? { id: p.qualificacao } : null,
    _regimeComunhao: p.regimeComunhao,
    regimeComunhao: !!p.regimeComunhao ? { id: p.regimeComunhao } : null,
    _genero: p.genero,
    genero: !!p.genero ? { id: p.genero } : null,
    _dataExpedicaoDocumentoPessoal: p.dataExpedicaoDocumentoPessoal,
    dataExpedicaoDocumentoPessoal: transformFromDate(
      p.dataExpedicaoDocumentoPessoal
    ),
    _documentoPessoal: p.documentoPessoal,
    documentoPessoal: !!p.documentoPessoal ? { id: p.documentoPessoal } : null,
  };

  if (p.dadosProfissionais) {
    result = {
      ...result,
      dadosProfissionais: {
        ...p.dadosProfissionais,
        _dataAdmissao: p.dadosProfissionais.dataAdmissao,
        dataAdmissao: transformFromDate(p.dadosProfissionais.dataAdmissao),
        _profissao: p.dadosProfissionais.profissao,
        profissao: !!p.dadosProfissionais.profissao
          ? { id: p.dadosProfissionais.profissao }
          : null,
        _ramoAtuacao: p.dadosProfissionais.ramoAtuacao,
        ramoAtuacao: !!p.dadosProfissionais.ramoAtuacao
          ? { id: p.dadosProfissionais.ramoAtuacao }
          : null,
        _segmentoServico: p.dadosProfissionais.segmentoServico,
        segmentoServico: !!p.dadosProfissionais.segmentoServico
          ? { id: p.dadosProfissionais.segmentoServico }
          : null,
        _tipoPensao: p.dadosProfissionais.tipoPensao,
        tipoPensao: !!p.dadosProfissionais.tipoPensao
          ? { id: p.dadosProfissionais.tipoPensao }
          : null,
        _tipoImovelAluguel: p.dadosProfissionais.tipoImovelAluguel,
        tipoImovelAluguel: !!p.dadosProfissionais.tipoImovelAluguel
          ? { id: p.dadosProfissionais.tipoImovelAluguel }
          : null,
      },
    };
  }
  if (p.conjuge) {
    result = {
      ...result,
      conjuge: {
        ...p.conjuge,
        _dataNascimento: p.conjuge.dataNascimento,
        dataNascimento: transformFromDate(p.conjuge.dataNascimento),
        _genero: p.conjuge.genero,
        genero: !!p.conjuge.genero ? { id: p.conjuge.genero } : null,
        _nacionalidade: p.conjuge.nacionalidade,
        nacionalidade: !!p.conjuge.nacionalidade
          ? { id: p.conjuge.nacionalidade }
          : null,
        _dataAdmissao: p.conjuge.dataAdmissao,
        dataAdmissao: transformFromDate(p.conjuge.dataAdmissao),
        _profissao: p.conjuge.profissao,
        profissao: !!p.conjuge.profissao ? { id: p.conjuge.profissao } : null,
        _escolaridade: p.conjuge.escolaridade,
        escolaridade: !!p.conjuge.escolaridade
          ? { id: p.conjuge.escolaridade }
          : null,
        _ramoAtuacao: p.conjuge.ramoAtuacao,
        ramoAtuacao: !!p.conjuge.ramoAtuacao
          ? { id: p.conjuge.ramoAtuacao }
          : null,
        _segmentoServico: p.conjuge.segmentoServico,
        segmentoServico: !!p.conjuge.segmentoServico
          ? { id: p.conjuge.segmentoServico }
          : null,
        _tipoPensao: p.conjuge.tipoPensao,
        tipoPensao: !!p.conjuge.tipoPensao
          ? { id: p.conjuge.tipoPensao }
          : null,
        _tipoImovelAluguel: p.conjuge.tipoImovelAluguel,
        tipoImovelAluguel: !!p.conjuge.tipoImovelAluguel
          ? { id: p.conjuge.tipoImovelAluguel }
          : null,
        _perfilRenda: p.conjuge.perfilRenda,
        perfilRenda: !!p.conjuge.perfilRenda
          ? { id: p.conjuge.perfilRenda }
          : null,
        _qualificacao: p.conjuge.qualificacao,
        qualificacao: !!p.conjuge.qualificacao
          ? { id: p.conjuge.qualificacao }
          : null,
        _dataExpedicaoDocumentoPessoal: p.conjuge.dataExpedicaoDocumentoPessoal,
        dataExpedicaoDocumentoPessoal: transformFromDate(
          p.conjuge.dataExpedicaoDocumentoPessoal
        ),
        _estadoCivil: p.conjuge.estadoCivil,
        estadoCivil: !!p.conjuge.estadoCivil
          ? { id: p.conjuge.estadoCivil }
          : null,
        _documentoPessoal: p.conjuge.documentoPessoal,
        documentoPessoal: !!p.conjuge.documentoPessoal
          ? { id: p.conjuge.documentoPessoal }
          : null,

      },
    };
    if (p.conjuge.contaLiberacao) {
      result = {
        ...result,
        conjuge: {
          ...result.conjuge,
          contaLiberacao: {
            ...result.conjuge.contaLiberacao,
            _tipoConta: p.conjuge.contaLiberacao.tipoConta,
            tipoConta: !!p.conjuge.contaLiberacao.tipoConta
              ? { id: p.conjuge.contaLiberacao.tipoConta }
              : null,
            _bancoConta: p.conjuge.contaLiberacao.bancoConta,
            bancoConta: !!p.conjuge.contaLiberacao.bancoConta
              ? { id: p.conjuge.contaLiberacao.bancoConta }
              : null,
          },
        }
      };
    }

  }
  if (p.endereco) {
    result = {
      ...result,
      endereco: {
        ...p.endereco,
        _tipoResidencia: p.endereco.tipoResidencia,
        tipoResidencia: !!p.endereco.tipoResidencia
          ? { id: p.endereco.tipoResidencia }
          : null,
      },
    };
  }

  if (p.contaLiberacao) {
    result = {
      ...result,
      contaLiberacao: {
        ...p.contaLiberacao,
        _tipoConta: p.contaLiberacao.tipoConta,
        tipoConta: !!p.contaLiberacao.tipoConta
          ? { id: p.contaLiberacao.tipoConta }
          : null,
        _bancoConta: p.contaLiberacao.bancoConta,
        bancoConta: !!p.contaLiberacao.bancoConta
          ? { id: p.contaLiberacao.bancoConta }
          : null,
      },
    };
  }
  return result;
};

const reenquadrarOperacao = basicFlow({
  actionGenerator: actions.reenquadrarOperacao,
  api: (values) => {
    const { id } = values;
    return authenticatedRequest({
      url: `/operacao/${id}/reenquadrar`,
      isResourceService: true,
      destiny: destinies.CLIENTE,
      body: values,
      method: "post",
    });
  },
  postSuccess: ({ response, original }) => {
    const { callback } = original;

    if (!!callback) {
      callback();
    }
  },
});

const obterRegrasSimulacao = basicFlow({
  actionGenerator: actions.obterRegrasSimulacao,
  api: () => {
    return authenticatedRequest({
      url: "/simuladores",
      destiny: destinies.SIMULADOR,
      method: "get",
    });
  },
  preSuccess: ({ response, original }) => {
    if (!!response.data) {
      const produto = response.data.filter(p => p.tipo === original.tipo)[0];
      const regra = produto.regras.filter(r => r.tipoPessoa === original.tipoPessoa)[0];
      response.data = regra;
    }
    return response;
  }
});

const aceitarNovosValoresOperacao = basicFlow({
  actionGenerator: actions.aceitarNovosValoresOperacao,
  api: (values) => {
    const { id } = values;
    return authenticatedRequest({
      url: `/operacao/${id}/aceitar-simulacao`,
      isResourceService: true,
      destiny: destinies.CLIENTE,
      body: values,
      method: "put",
    });
  },
  postSuccess: function* ({ response, original }) {
    const { callback: originalCallback } = original;

    const callback = () => {
      if (!!originalCallback) {
        originalCallback();
      }
      toast.success("Proposta reenquadrada com sucesso!");
    };

    yield put(
      actions.refreshPreenchimento.request({
        id: original.id,
        callback,
      })
    );
  },
});

const recalcular = basicFlow({
  actionGenerator: actions.recalcularSimulacao,
  transform: function* (payload) {
    const { operacao } = yield select(getPreenchimentoOperacao);
    const compradorPrincipal = operacao.participantes.find(
      (c) => !!c.principal
    );
    const participantes = operacao.participantes.filter((c) => !c.principal);
    return {
      ...payload,
      entrada: {
        ...payload.entrada,
        nomeCliente: operacao.nomeCliente,
        cpfCnpjCliente: operacao.cpfCnpjCliente,
        emailCliente: compradorPrincipal.email,
        telefoneCliente: compradorPrincipal.telefone,
        rendaCliente: compradorPrincipal.renda,
        outrosParticipantes: participantes.map((p) => ({
          nomeCliente: p.nome,
          cpfCnpjCliente: p.cpfCnpj,
          telefoneCliente: p.telefone,
          rendaCliente: p.renda || 0,
        })),
        portabilidade: operacao.detalhe.portabilidade,
        ufImovel: operacao.imovel.endereco.uf,
      },
    };
  },
  api: (values) => {
    return authenticatedRequest({
      url: "/resimular",
      isResourceService: true,
      destiny: destinies.SIMULADOR,
      method: "post",
      body: values,
    });
  },
});

const extratoSimulacao = basicFlow({
  actionGenerator: actions.extratoNovaSimulacao,
  transform: function* (payload) {
    const { operacao } = yield select(getPreenchimentoOperacao);
    const compradorPrincipal = operacao.participantes.find(
      (c) => !!c.principal
    );
    const participantes = operacao.participantes.filter((c) => !c.principal);
    return {
      ...payload,
      entrada: {
        ...payload.entrada,
        nomeCliente: operacao.nomeCliente,
        cpfCnpjCliente: operacao.cpfCnpjCliente,
        emailCliente: compradorPrincipal.email,
        telefoneCliente: compradorPrincipal.telefone,
        rendaCliente: compradorPrincipal.renda,
        outrosParticipantes: participantes.map((p) => ({
          nomeCliente: p.nome,
          cpfCnpjCliente: p.cpfCnpj,
          telefoneCliente: p.telefone,
          rendaCliente: p.renda || 0,
        })),
        portabilidade: operacao.detalhe.portabilidade,
        ufImovel: operacao.imovel.endereco.uf,
      },
    };
  },
  api: (values) => {
    return authenticatedRequest({
      url: "/download",
      destiny: destinies.SIMULADOR,
      method: "post",
      body: values,
    });
  },
  postSuccess: ({ response }) => {
    let a = document.createElement("a");
    a.href = response.data;
    a.target = "_blank";
    a.click();
  },
});

const obterOperacao = basicFlow({
  actionGenerator: actions.obterOperacao,
  api: ({ id }) => {
    return authenticatedRequest({
      url: `/operacao/${id}`,
      isResourceService: true,
      destiny: destinies.CLIENTE,
      method: "get",
    });
  },
});

const obterContatoIq = basicFlow({
  actionGenerator: actions.obterContatoIq,
  api: ({ id }) => {
    return authenticatedRequest({
      url: `/dominios/banco/${id}/contato-iq`,
      isResourceService: true,
      destiny: destinies.CLIENTE,
      method: "get",
    });
  },
  postSuccess: function* ({ response, original }) {
    if (!!original.successCallback) {
      yield original.successCallback(response, original.value);
    }
  },
});

const compartilharOperacao = basicFlow({
  actionGenerator: actions.compartilharOperacao,
  api: ({ id }) => {
    return authenticatedRequest({
      url: `/operacao/${id}/compartilhamento`,
      isResourceService: true,
      destiny: destinies.CLIENTE,
      method: "get",
    });
  },
  postSuccess: function* ({ response }) {
    yield put(actions.exibirDialogCompartilhamento(true, response.data));
  },
});

const obterFiltrosOperacoes = basicFlow({
  actionGenerator: actions.obterFiltrosOperacoes,
  api: () => {
    return authenticatedRequest({
      url: `/operacoes/filtros`,
      isResourceService: true,
      destiny: destinies.CLIENTE,
      method: "get",
    });
  },
});

const listarOperacoes = basicFlow({
  actionGenerator: actions.listarOperacoes,
  transform: ({ filters, page, size, orderBy, order }) => {
    return {
      filters: {
        ...filters,
        situacao: filters.situacao?.length > 0 ? filters.situacao.map(i => i.id) : [],
        fase: filters.fase?.length > 0 ? filters.fase.map(i => i.id) : [],
        parceiro: filters.parceiro ? [filters.parceiro?.id] : [],
        responsavel: filters.responsavel ? [filters.responsavel?.id] : [],
        nomeOuCpfCnpj: filters.nomeOuCpfCnpj
          ?.replaceAll(".", "")
          ?.replaceAll("-", ""),
      },
      page,
      size,
      orderBy,
      order,
    };
  },
  api: ({ filters, page, size, orderBy, order }) => {
    return authenticatedRequest({
      url: `/operacoes?page=${page}&size=${size}&orderBy=${orderBy}&order=${order}`,
      isResourceService: true,
      destiny: destinies.CLIENTE,
      body: filters,
      method: "post",
    });
  },
});

const iniciarRelacionada = basicFlow({
  actionGenerator: actions.iniciarRelacionada,
  api: ({ regra, correntista }) => {
    return authenticatedRequest({
      url: `/operacao-relacionada/${regra}`,
      isResourceService: true,
      destiny: destinies.CLIENTE,
      queryParams: { correntista },
      method: "post",
    });
  },
  postSuccess: function* ({ response }) {
    if (!!response.data?.id) {
      yield put(
        routeActions.redirectTo(routes.PREENCHIMENTO_OPERACAO, {
          id: response.data.id,
        })
      );
      toast.success("Proposta gerada com sucesso");
    }
  },
});
const refresh = basicFlow({
  actionGenerator: actions.refresh,
  transform: (original) => {
    return {
      id: original.id,
    };
  },
  api: ({ id }) => {
    return authenticatedRequest({
      url: `/operacao/${id}`,
      isResourceService: true,
      destiny: destinies.CLIENTE,
      method: "get",
    });
  },
  postSuccess: ({ original }) => {
    if (!!original.acao) {
      toast.success(`Ação "${original.acao.nome}" executada com sucesso`);
    } else if (!!original.workflow) {
      toast.success(`Cancelamento executado com sucesso`);
    }

    if (!!original.handleClose) {
      original.handleClose();
    }
  },
});

const obterPreenchimento = basicFlow({
  actionGenerator: actions.obterPreenchimento,
  api: ({ id }) => {
    return authenticatedRequest({
      url: `/operacao/${id}/preenchimento`,
      isResourceService: true,
      destiny: destinies.CLIENTE,
      method: "get",
    });
  },
  preSuccess: tratarResultPreenchimento,
  postSuccess: function* () {
    const { operacao } = yield select(getPreenchimentoOperacao);
    const participantePrincipal = operacao.participantes.find(p => !!p.principal);

    yield put(
      actions.obterRegrasSimulacao.request({
        tipo: operacao.tipoProduto,
        tipoPessoa: participantePrincipal.tipoPessoa,
      })
    );
  }
});
const enviarPreenchimento = basicFlow({
  actionGenerator: actions.enviarPreenchimento,
  transform: ({ callback, ...values }) => {
    return values;
  },
  api: (values) => {
    return authenticatedRequest({
      url: `/operacao/${values.id}/preenchimento`,
      isResourceService: true,
      destiny: destinies.CLIENTE,
      method: "post",
    });
  },
  postSuccess: function* ({ original }) {
    const { callback, id } = original;

    if (!!callback) {
      callback();
    } else {
      yield put(routeActions.redirectTo(routes.DETALHES_OPERACAO, { id }));
    }
  },
});
const gravarPreenchimento = basicFlow({
  actionGenerator: actions.gravarPreenchimento,
  transform: ({ callback, ...values }) => {
    return values;
  },
  api: (values) => {
    return authenticatedRequest({
      url: `/operacao/${values.operacao.id}/preenchimento`,
      isResourceService: true,
      destiny: destinies.CLIENTE,
      method: "put",
      body: values,
    });
  },
  preSuccess: tratarResultPreenchimento,
  postSuccess: ({ original, response }) => {
    if (original.callback) {
      original.callback(response.data);
    }
  },
});
const refreshPreenchimento = basicFlow({
  actionGenerator: actions.refreshPreenchimento,
  api: ({ id }) => {
    return authenticatedRequest({
      url: `/operacao/${id}/preenchimento`,
      isResourceService: true,
      destiny: destinies.CLIENTE,
      method: "get",
    });
  },
  preSuccess: tratarResultPreenchimento,
  postSuccess: ({ original }) => {
    if (!!original.callback) {
      original.callback();
    }
  },
});
const adicionarParticipantePreenchimento = basicFlow({
  actionGenerator: actions.adicionarParticipantePreenchimento,
  api: ({ id, ...values }) => {
    return authenticatedRequest({
      url: `/operacao/${id}/participante`,
      isResourceService: true,
      destiny: destinies.CLIENTE,
      method: "post",
      body: values,
    });
  },
  postSuccess: function* ({ original }) {
    const callback = () => {
      toast.success("Participante adicionado com sucesso");
    };

    yield put(
      actions.refreshPreenchimento.request({
        id: original.id,
        callback,
      })
    );
  },
});
const atualizarParticipantePreenchimento = basicFlow({
  actionGenerator: actions.atualizarParticipantePreenchimento,
  transform: ({ callback, ...other }) => ({ ...other }),
  api: ({ id, ...values }) => {
    return authenticatedRequest({
      url: `/participante/${id}/refresh`,
      isResourceService: true,
      destiny: destinies.CLIENTE,
      method: "post",
      queryParams: values,
    });
  },
  preSuccess: tratarResultPreenchimento,
  postSuccess: ({ original, response }) => {
    if (!!original.callback) {
      original.callback(response.data);
    }
  },
});
const removerParticipantePreenchimento = basicFlow({
  actionGenerator: actions.removerParticipantePreenchimento,
  api: ({ id, participante }) => {
    return authenticatedRequest({
      url: `/operacao/${id}/participante/${participante}`,
      isResourceService: true,
      destiny: destinies.CLIENTE,
      method: "delete",
    });
  },
  postSuccess: function* ({ original }) {
    const callback = () => {
      toast.success("Participante removido com sucesso");
    };

    yield put(
      actions.refreshPreenchimento.request({
        id: original.id,
        callback,
      })
    );
  },
});

const obterDominiosOperacao = basicFlow({
  actionGenerator: actions.obterDominiosOperacao,
  api: ({ id }) => {
    return authenticatedRequest({
      url: `/operacao/${id}/dominios`,
      isResourceService: true,
      destiny: destinies.CLIENTE,
      method: "get",
    });
  },
});

const obterDocumentos = basicFlow({
  actionGenerator: actions.obterDocumentos,
  api: ({ id }) => {
    return authenticatedRequest({
      url: `/operacao/${id}/documentos`,
      isResourceService: true,
      destiny: destinies.CLIENTE,
      method: "get",
    });
  },
});

const atualizarDocumentos = basicFlow({
  actionGenerator: actions.atualizarDocumentos,
  api: ({ id, tipos }) => {
    return authenticatedRequest({
      url: `/operacao/${id}/documentos`,
      isResourceService: true,
      destiny: destinies.CLIENTE,
      method: "post",
      body: {
        tipos,
      },
    });
  },
  postSuccess: ({ original }) => {
    const { callback } = original;
    if (!!callback) {
      callback();
    }
  },
});
const prepararUploadDocumento = basicFlow({
  actionGenerator: actions.prepararUploadDocumento,
  transform: (payload) => {
    return {
      id: payload.id,
      type: "prepare",
      arquivos: payload.files.map((f) => ({ size: f.size })),
    };
  },
  api: (values) => {
    return authenticatedRequest({
      url: `/documento-operacao/${values.id}`,
      isResourceService: true,
      destiny: destinies.UPLOAD,
      method: "put",
      body: values,
    });
  },
  postSuccess: function* ({ original, response }) {
    yield put(
      actions.realizarUploadDocumento.request({
        ...original,
        ...response.data,
        arquivos: original.files.map((a, i) => ({
          ...response.data.arquivos[i],
          file: a,
        })),
      })
    );
  },
});
const realizarUploadDocumento = basicFlow({
  actionGenerator: actions.realizarUploadDocumento,
  transform: (payload) => {
    return payload.arquivos.map((f) => {
      const form = new FormData();
      form.append("key", f.key);
      form.append("bucket", f.bucket);
      form.append("X-Amz-Signature", f["X-Amz-Signature"]);
      form.append("Policy", f.Policy);
      form.append("X-Amz-Algorithm", f["X-Amz-Algorithm"]);
      form.append("X-Amz-Credential", f["X-Amz-Credential"]);
      form.append("X-Amz-Date", f["X-Amz-Date"]);
      form.append("file", f.file);

      return {
        url: f.url,
        form,
      };
    });
  },
  api: (arquivos) => {
    return Promise.all(
      arquivos.map(async (f) => {
        return unauthenticatedRequest({
          url: f.url,
          method: "post",
          body: f.form,
        });
      })
    );
  },
  postSuccess: function* ({ original }) {
    yield put(
      actions.finalizarUploadDocumento.request({
        ...original,
      })
    );
  },
});
const finalizarUploadDocumento = basicFlow({
  actionGenerator: actions.finalizarUploadDocumento,
  transform: (payload) => {
    return {
      id: payload.id,
      type: "finalize",
      arquivos: payload.arquivos.map((f) => ({
        artefato: f.key,
        nome: f.file.name,
      })),
    };
  },
  api: (values) => {
    return authenticatedRequest({
      url: `/documento-operacao/${values.id}`,
      isResourceService: true,
      destiny: destinies.UPLOAD,
      method: "put",
      body: values,
    });
  },
  postSuccess: ({ original }) => {
    toast.success(
      original.arquivos.length > 1
        ? "Arquivos enviados com sucesso"
        : "Arquivo enviado com sucesso"
    );
  },
});
const downloadDocumento = basicFlow({
  actionGenerator: actions.downloadDocumento,
  api: ({ id }) => {
    return authenticatedRequest({
      url: `/artefato/${id}/pre-signed-url`,
      isResourceService: true,
      // responseType: "arraybuffer",
      destiny: destinies.DOCUMENTO,
      method: "get",
    });
  },
  postSuccess: ({ original: { tipo }, response }) => {
    let a = document.createElement("a");
    a.href = response.data;
    a.target = "_blank";
    a.click();
  },
});
const downloadTodosDocumentos = basicFlow({
  actionGenerator: actions.downloadTodosDocumentos,
  api: ({ id }) => {
    return authenticatedRequest({
      url: `/operacao/${id}/documentos`,
      isResourceService: true,
      destiny: destinies.DOCUMENTO,
      method: "post",
    });
  },
  postSuccess: () => {
    toast.success(
      "Solicitação realizada com sucesso.\nEm breve você receberá os documentos da proposta em seu e-mail."
    );
  },
});
const downloadTemplate = basicFlow({
  actionGenerator: actions.downloadTemplate,
  api: ({ id, preencherAutomaticamente }) => {
    return authenticatedRequest({
      url: `/documento-operacao/${id}/template?preencherAutomaticamente=${preencherAutomaticamente}`,
      isResourceService: true,
      responseType: "arraybuffer",
      destiny: destinies.DOCUMENTO,
      method: "get",
    });
  },
  postSuccess: ({ response }) => {
    const fileName = getFileNameFromHeader(
      response.headers,
      "content-disposition"
    );

    if (!!fileName) {
      saveAs(new Blob([response.data]), fileName);
    }
  },
});
const removerArquivo = basicFlow({
  actionGenerator: actions.removerArquivo,
  api: ({ id, documento }) => {
    return authenticatedRequest({
      url: `/documento-operacao/${documento}/artefato/${id}`,
      isResourceService: true,
      destiny: destinies.DOCUMENTO,
      method: "delete",
    });
  },
  postSuccess: () => {
    toast.success("Arquivo removido com sucesso");
  },
});

const obterDadosBancarios = basicFlow({
  actionGenerator: actions.obterDadosBancarios,
  api: ({ id }) => {
    return authenticatedRequest({
      url: `/operacao/${id}/dados-bancarios`,
      isResourceService: true,
      destiny: destinies.CLIENTE,
      method: "get",
    });
  },
  // preSuccess: tratarResultPreenchimento,
});

const enviarDadosBancarios = basicFlow({
  actionGenerator: actions.enviarDadosBancarios,
  api: (values) => {
    return authenticatedRequest({
      url: `/operacao/${values.operacao.id}/dados-bancarios`,
      isResourceService: true,
      destiny: destinies.CLIENTE,
      method: "post",
      body: values,
    });
  },
  postSuccess: function* ({ original }) {
    toast.success("Dados bancários atualizados com sucesso.");

    const { operacao } = original;
    yield put(
      routeActions.redirectTo(routes.DETALHES_OPERACAO, { id: operacao.id })
    );
  },
});

const obterComplemento = basicFlow({
  actionGenerator: actions.obterComplemento,
  api: ({ id }) => {
    return authenticatedRequest({
      url: `/operacao/${id}/complemento`,
      isResourceService: true,
      destiny: destinies.CLIENTE,
      method: "get",
    });
  },
  preSuccess: tratarResultPreenchimento,
});

const enviarComplemento = basicFlow({
  actionGenerator: actions.enviarComplemento,
  api: (values) => {
    return authenticatedRequest({
      url: `/operacao/${values.id}/complemento`,
      isResourceService: true,
      destiny: destinies.CLIENTE,
      method: "post",
    });
  },
  postSuccess: function* ({ original }) {
    const { id } = original;
    yield put(routeActions.redirectTo(routes.DETALHES_OPERACAO, { id }));
  },
});
const atualizarResponsavelOperacao = basicFlow({
  actionGenerator: actions.atualizarResponsavel,
  api: (values) => {
    return authenticatedRequest({
      url: `/operacao/${values.id}/responsavel`,
      isResourceService: true,
      destiny: destinies.CLIENTE,
      method: "put",
      body: values,
    });
  },
});
const gravarComplemento = basicFlow({
  actionGenerator: actions.gravarComplemento,
  transform: ({ callback, ...values }) => {
    return values;
  },
  api: (values) => {
    return authenticatedRequest({
      url: `/operacao/${values.operacao.id}/complemento`,
      isResourceService: true,
      destiny: destinies.CLIENTE,
      method: "put",
      body: values,
    });
  },
  preSuccess: tratarResultPreenchimento,
  postSuccess: ({ original, response }) => {
    if (original.callback) {
      original.callback(response.data);
    }
  },
});
const refreshComplemento = basicFlow({
  actionGenerator: actions.refreshComplemento,
  api: ({ id }) => {
    return authenticatedRequest({
      url: `/operacao/${id}/complemento`,
      isResourceService: true,
      destiny: destinies.CLIENTE,
      method: "get",
    });
  },
  preSuccess: tratarResultPreenchimento,
  postSuccess: ({ original }) => {
    if (!!original.callback) {
      original.callback();
    }
  },
});
const adicionarParticipanteComplemento = basicFlow({
  actionGenerator: actions.adicionarParticipanteComplemento,
  api: ({ id, ...values }) => {
    return authenticatedRequest({
      url: `/operacao/${id}/participante`,
      isResourceService: true,
      destiny: destinies.CLIENTE,
      method: "post",
      body: values,
    });
  },
  postSuccess: function* ({ values, original }) {
    const callback = () => {
      if (original?.tipoParticipante == "CO") {
        toast.success("Comprador adicionado com sucesso");
      } else if (original?.tipoParticipante == "VD") {
        toast.success("Vendedor adicionado com sucesso");
      } else if (original?.tipoParticipante == "RL") {
        toast.success("Sócio adicionado com sucesso");
      }
    };

    yield put(
      actions.refreshComplemento.request({
        id: values.id,
        callback,
      })
    );
  },
});

const removerParticipanteComplemento = basicFlow({
  actionGenerator: actions.removerParticipanteComplemento,
  api: ({ id, participante }) => {
    return authenticatedRequest({
      url: `/operacao/${id}/participante/${participante}`,
      isResourceService: true,
      destiny: destinies.CLIENTE,
      method: "delete",
    });
  },
  postSuccess: function* ({ original }) {
    const callback = () => {
      if (original?.tipoParticipante == "CO") {
        toast.success("Comprador removido com sucesso");
      } else if (original?.tipoParticipante == "VD") {
        toast.success("Vendedor removido com sucesso");
      } else if (original?.tipoParticipante == "RL") {
        toast.success("Sócio removido com sucesso");
      }
    };

    yield put(
      actions.refreshComplemento.request({
        id: original.id,
        callback,
      })
    );
  },
});

const obterConfirmacaoValores = basicFlow({
  actionGenerator: actions.obterConfirmacaoValores,
  api: ({ id }) => {
    return authenticatedRequest({
      url: `/operacao/${id}/confirmacao-valores`,
      isResourceService: true,
      destiny: destinies.CLIENTE,
      method: "get",
    });
  }
});
const enviarConfirmacaoValores = basicFlow({
  actionGenerator: actions.enviarConfirmacaoValores,
  api: (values) => {
    return authenticatedRequest({
      url: `/operacao/${values.operacaoConfirmacaoValores.idOperacao}/confirmacao-valores?passo=${values.passo}&instituicao=${values.instituicao}`,
      isResourceService: true,
      destiny: destinies.CLIENTE,
      method: "put",
      body: values.operacaoConfirmacaoValores,
    });
  },
  postSuccess: function* ({ original, response }) {
    const { callback } = original;
    if (!!callback) {
      yield callback(response.data);
    }
  },
});

const obterConjuge = basicFlow({
  actionGenerator: actions.obterConjuge,
  transform: ({ callback, ...values }) => {
    return values;
  },
  api: (values) => {
    return authenticatedRequest({
      url: `/cliente-conjuge`,
      isResourceService: true,
      destiny: destinies.CLIENTE,
      method: "get",
      queryParams: values,
    });
  },
  postSuccess: function* ({ original, response }) {
    const { callback } = original;
    if (!!callback) {
      callback(response.data);
    }
  },
});

const obterOperacaoAprovacaoMenor = basicFlow({
  actionGenerator: actions.obterOperacaoAprovacaoMenor,
  api: ({ id }) => {
    return authenticatedRequest({
      url: `/operacao/${id}/aprovacao-menor`,
      isResourceService: true,
      destiny: destinies.CLIENTE,
      method: "get",
    });
  },
  postSuccess: function* ({ original, response }) {
    if (!response.data) {
      yield put(
        routeActions.redirectTo(routes.DETALHES_OPERACAO, {
          id: original.id,
        })
      );
    }
  },
});

const atualizarOperacaoAprovacaoMenor = basicFlow({
  actionGenerator: actions.atualizarOperacaoAprovacaoMenor,
  api: ({ id, decisao }) => {
    return authenticatedRequest({
      url: `/operacao/${id}/aprovacao-menor?tipoDecisao=${decisao}`,
      isResourceService: true,
      destiny: destinies.CLIENTE,
      method: "put",
    });
  },
  postSuccess: function* ({ original, response }) {
    if (response.data) {
      yield put(
        routeActions.redirectTo(routes.DETALHES_OPERACAO, {
          id: original.id,
        })
      );
    }
  },
});

const atualizarPrevisaoPagamento = basicFlow({
  actionGenerator: actions.atualizarPrevisaoPagamento,
  transform: ({ callback, ...values }) => {
    return values;
  },
  api: ({ id, ...values }) => {
    return authenticatedRequest({
      url: `/detalhe-operacao/${id}/previsao-pagamento`,
      isResourceService: true,
      destiny: destinies.CLIENTE,
      method: "post",
      queryParams: values,
    });
  },
  postSuccess: function* ({ original, response }) {
    const { callback } = original;
    if (!!callback) {
      yield callback(response.data);
    }
  },
});

const atualizarImovelDefinido = basicFlow({
  actionGenerator: actions.atualizarImovelDefinido,
  transform: ({ callback, ...values }) => {
    return values;
  },
  api: ({ id, ...values }) => {
    return authenticatedRequest({
      url: `/detalhe-operacao/${id}/imovel-definido`,
      isResourceService: true,
      destiny: destinies.CLIENTE,
      method: "post",
      queryParams: values,
    });
  },
  postSuccess: function* ({ original, response }) {
    const { callback } = original;
    if (!!callback) {
      yield callback(response.data);
    }
  },
});

const atualizarPermiteAutomacao = basicFlow({
  actionGenerator: actions.atualizarPermiteAutomacao,
  transform: ({ callback, ...values }) => {
    return values;
  },
  api: ({ id, ...values }) => {
    return authenticatedRequest({
      url: `/operacao/${id}/permite-automacao`,
      isResourceService: true,
      destiny: destinies.CLIENTE,
      method: "post",
      queryParams: values,
    });
  },
  postSuccess: function* ({ original, response }) {
    const { callback } = original;
    if (!!callback) {
      yield callback(response.data);
    }
  },
});

function* detalhesOperacaoRouteWatcher() {
  yield routeWatcher([routes.DETALHES_OPERACAO], function* () {
    const payload = yield select(getRouteArguments);
    yield put(actions.obterOperacao.request(payload));
    yield put(actions.obterDominiosOperacao.request(payload));
  });
}
function* preenchimentoOperacaoRouteWatcher() {
  yield routeWatcher([routes.PREENCHIMENTO_OPERACAO], function* () {
    const payload = yield select(getRouteArguments);
    yield put(actions.obterPreenchimento.request(payload));
  });
}
function* complementoOperacaoRouteWatcher() {
  yield routeWatcher([routes.COMPLEMENTO_OPERACAO], function* () {
    const payload = yield select(getRouteArguments);
    yield put(actions.obterComplemento.request(payload));
  });
}

function* dadosBancariosRouteWatcher() {
  yield routeWatcher([routes.DADOS_BANCARIOS], function* () {
    const payload = yield select(getRouteArguments);
    yield put(actions.obterDadosBancarios.request(payload));
  });
}

function* confirmacaoValoresOperacaoRouteWatcher() {
  yield routeWatcher([routes.CONFIRMACAO_VALORES], function* () {
    const payload = yield select(getRouteArguments);
    yield put(actions.obterConfirmacaoValores.request(payload));
  });
}

function* aprovacaoMenorOperacaoRouteWatcher() {
  yield routeWatcher([routes.APROVACAO_MENOR], function* () {
    const payload = yield select(getRouteArguments);
    yield put(actions.obterOperacaoAprovacaoMenor.request(payload));
  });
}

export const sagas = [
  obterOperacao.watcher(),
  obterConjuge.watcher(),
  listarOperacoes.watcher(),
  obterFiltrosOperacoes.watcher(),
  atualizarResponsavelOperacao.watcher(),
  compartilharOperacao.watcher(),
  refresh.watcher(),
  adicionarParticipantePreenchimento.watcher(),
  atualizarParticipantePreenchimento.watcher(),
  removerParticipantePreenchimento.watcher(),
  refreshPreenchimento.watcher(),
  obterPreenchimento.watcher(),
  gravarPreenchimento.watcher(),
  enviarPreenchimento.watcher(),
  iniciarRelacionada.watcher(),
  prepararUploadDocumento.watcher(),
  realizarUploadDocumento.watcher(),
  finalizarUploadDocumento.watcher(),
  downloadDocumento.watcher(),
  removerArquivo.watcher(),
  downloadTemplate.watcher(),
  obterDocumentos.watcher(),
  atualizarDocumentos.watcher(),
  obterComplemento.watcher(),
  gravarComplemento.watcher(),
  enviarComplemento.watcher(),
  adicionarParticipanteComplemento.watcher(),
  removerParticipanteComplemento.watcher(),
  refreshComplemento.watcher(),
  obterDominiosOperacao.watcher(),
  obterDadosBancarios.watcher(),
  enviarDadosBancarios.watcher(),
  obterConfirmacaoValores.watcher(),
  enviarConfirmacaoValores.watcher(),
  downloadTodosDocumentos.watcher(),
  obterOperacaoAprovacaoMenor.watcher(),
  atualizarOperacaoAprovacaoMenor.watcher(),
  atualizarPrevisaoPagamento.watcher(),
  atualizarImovelDefinido.watcher(),
  atualizarPermiteAutomacao.watcher(),
  obterContatoIq.watcher(),
  reenquadrarOperacao.watcher(),
  recalcular.watcher(),
  extratoSimulacao.watcher(),
  aceitarNovosValoresOperacao.watcher(),
  obterRegrasSimulacao.watcher(),
  dadosBancariosRouteWatcher(),
  complementoOperacaoRouteWatcher(),
  detalhesOperacaoRouteWatcher(),
  preenchimentoOperacaoRouteWatcher(),
  confirmacaoValoresOperacaoRouteWatcher(),
  aprovacaoMenorOperacaoRouteWatcher(),
];
