import ParametrosSimulacaoSection from "./ParametrosSimulacaoSection";
import { useDispatch, useSelector } from "react-redux";
import {
  getDadosCliente,
  getResultadoSimulador,
} from "../../selectors/simulador.selectors";
import { useCallback, useEffect, useMemo, useState } from "react";
import { actions } from "../../actions/simulador.actions";
import useCompleteForm from "../../hooks/useCompleteForm";
import validators from "../../utils/validators";
import formatters from "../../utils/formatters";
import { parseDateUsingFormat } from "../../utils/basic";
import { addMonths, differenceInMonths } from "date-fns";
import {
  getSimuladoresDisponiveis,
  getSimuladorSelecionado,
} from "../../selectors/simulador.selectors";
import { toast } from "react-toastify";

const ParametrosSimulacaoSectionConnected = () => {
  const dispatch = useDispatch();
  const [mensagemFinanciamento, setMensagemFinanciamento] = useState("");
  const [mensagemPrazo, setMensagemPrazo] = useState("");
  const data = useSelector(getSimuladorSelecionado);
  const { data: disponiveis } = useSelector(getSimuladoresDisponiveis);
  const { submitting, entrada: valores } = useSelector(getResultadoSimulador);
  const dadosCliente = useSelector(getDadosCliente);

  const menorData = useMemo(() => {
    return [dadosCliente.dataNascimento, dadosCliente.dataNascimento2]
      .filter((p) => !!p)
      .sort(
        (a, b) =>
          parseDateUsingFormat(a, "dd/MM/yyyy") -
          parseDateUsingFormat(b, "dd/MM/yyyy")
      )[0];
  }, [dadosCliente]);

  const successCallback = useCallback(
    ({ response }) => {
      if (response.data.grupos.length > 0) {
        dispatch(actions.navegar(3));
      } else {
        toast.error(
          "Nenhuma oferta comercial encontrada com as condições selecionadas."
        );
      }
    },
    [dispatch]
  );

  const [formProps, handleSubmit] = useCompleteForm({
    rules: (form) => ({
      tipoImovel: validators.string({ required: true }),
      ufImovel: validators.string({ required: true }),
      valorImovel: validators.number({
        required: true,
        custom: {
          valorMaximo: (value) => {
            if (!!data && data.valorMaximoImovel < value) {
              return `Valor do Imóvel não pode ultrapassar R$ ${formatters.numbers.currency(
                data.valorMaximoImovel
              )}`;
            }
            return true;
          },
        },
      }),
      valorFinanciamento: validators.number({
        required: true,
        custom: {
          valorMaximo: (value) => {
            const valorImovel = form.getValues("valorImovel");
            if (!valorImovel || valorImovel === 0) {
              return true;
            }
            const ltv = data.percentualMaximoLtv;
            const financiaCustas = form.getValues("financiaCustas");

            const valorFinanciamentoTotal = financiaCustas
              ? (value + valorImovel * 0.05).toFixed(2)
              : value;

            const valorMaximo = financiaCustas
              ? (valorImovel * (ltv - 5)) / 100
              : (valorImovel * ltv) / 100;

            if ((valorFinanciamentoTotal * 100) / valorImovel > ltv) {
              if (financiaCustas) {
                return `Valor do Financiamento + Custas não pode ultrapassar ${formatters.numbers.nFixed(
                  ltv,
                  1
                )}% do Valor do Imóvel. Reduza o valor do financiamento para até R$ ${formatters.numbers.currency(
                  valorMaximo
                )}.`;
              } else {
                return `Valor do Financiamento não pode ultrapassar ${formatters.numbers.nFixed(
                  ltv,
                  1
                )}% do Valor do Imóvel. Reduza o valor do financiamento para até R$ ${formatters.numbers.currency(
                  valorMaximo
                )}.`;
              }
            }
            return true;
          },
        },
      }),
      prazoDesejado: validators.number({
        required: true,
        custom: {
          valorMaximo: (value) => {
            if (!!data && data.prazoMaximo < value) {
              return `Prazo desejado não pode ultrapassar ${data.prazoMaximo}`;
            }
            return true;
          },
          prazoMaximoSeguro: (value) => {
            const dataNascimentoCampo = menorData;

            if (
              !!value &&
              !!data &&
              data.tipoPessoa === "F" &&
              !!dataNascimentoCampo
            ) {
              const dataNascimento = parseDateUsingFormat(
                dataNascimentoCampo,
                "dd/MM/yyyy"
              );
              const dataFinal = addMonths(dataNascimento, 80 * 12);
              const mesesRestantes = differenceInMonths(dataFinal, new Date());

              return (
                value <= mesesRestantes ||
                `Prazo desejadp no pode ultrapassar ${mesesRestantes}, pois o prazo + idade informada não podem ultrapassar 80 anos.`
              );
            }
            return true;
          },
        },
      }),
    }),
    initialValues: useCallback(
      () => ({
        tipoImovel:
          valores?.tipoImovel ??
          (data.tiposImovel.length === 1 ? data.tiposImovel[0].id : ""),
        ufImovel: valores?.ufImovel ?? "",
        valorImovel: valores?.valorImovel ?? "",
        valorFinanciamento: valores?.valorFinanciamento ?? "",
        prazoDesejado: valores?.prazoDesejado ?? "",
        financiaCustas: valores?.financiaCustas ?? false,
        portabilidade: valores?.portabilidade ?? false,
        financiaIof: valores?.financiaIof ?? true,
        financiaTarifaEmissao: valores?.financiaTarifaEmissao ?? true,
        financiaTarifaRegistro: valores?.financiaTarifaRegistro ?? true,
      }),
      [data, valores]
    ),
    handleSubmit: useCallback(
      (values) => {
        dispatch(
          actions.calcular.request({ ...values, callback: successCallback })
        );
      },
      [dispatch, successCallback]
    ),
  });

  const tipoProduto = disponiveis.find(
    (p) => !!p.regras.find((g) => g.id === data.id)
  )?.tipo;

  const handleGoBack = useCallback(() => {
    dispatch(actions.navegar(1));
  }, [dispatch]);

  const valorImovel = formProps.watch("valorImovel");
  const financiaCustas = formProps.watch("financiaCustas");

  const handlePrazoMessage = useCallback(() => {
    if (!!data) {
      if (!menorData || menorData.length !== 10) {
        setMensagemPrazo(null);
        return;
      }
      const { prazoMaximo } = data;

      const dataNascimentoDate = parseDateUsingFormat(menorData, "dd/MM/yyyy");
      const dataFinal = addMonths(dataNascimentoDate, 80 * 12);
      const mesesRestantes = differenceInMonths(dataFinal, new Date());

      setMensagemPrazo(
        `Prazo máximo: ${Math.min(mesesRestantes, prazoMaximo)} meses`
      );
      return;
    }
    setMensagemPrazo(null);
  }, [menorData, setMensagemPrazo, data]);

  const handleFinanciamentoMessage = useCallback(() => {
    if (!!data) {
      if (!valorImovel || valorImovel === 0) {
        setMensagemFinanciamento(null);
        return;
      }
      const ltv = data.percentualMaximoLtv;
      const valorMaximo = financiaCustas
        ? (valorImovel * (ltv - 5)) / 100
        : (valorImovel * ltv) / 100;

      if (tipoProduto === "H") {
        setMensagemFinanciamento(
          `Valor máximo de empréstimo: R$ ${formatters.numbers.currency(
            valorMaximo
          )}.`
        );
      } else if (tipoProduto === "C") {
        setMensagemFinanciamento(
          `Valor máximo de consórcio: R$ ${formatters.numbers.currency(
            valorMaximo
          )}.`
        );
      } else {
        setMensagemFinanciamento(
          `Valor máximo de financiamento: R$ ${formatters.numbers.currency(
            valorMaximo
          )}.`
        );
      }
      return;
    }
    setMensagemFinanciamento(null);
  }, [
    valorImovel,
    financiaCustas,
    setMensagemFinanciamento,
    data,
    tipoProduto,
  ]);

  useEffect(() => {
    handleFinanciamentoMessage();
    handlePrazoMessage();
  }, [handleFinanciamentoMessage, handlePrazoMessage]);

  return (
    <ParametrosSimulacaoSection
      condicao={data}
      formProps={formProps}
      handleGoBack={handleGoBack}
      tipoProduto={tipoProduto}
      handleSubmit={handleSubmit}
      submitting={submitting}
      mensagemFinanciamento={mensagemFinanciamento}
      mensagemPrazo={mensagemPrazo}
    />
  );
};

export default ParametrosSimulacaoSectionConnected;
